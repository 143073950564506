<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Промо</v-card-title>
        <v-card-actions>
          <!--          <v-btn @click="">Добавить</v-btn>-->
        </v-card-actions>
        <v-card-text>
          <v-form lazy-validation
                  ref="form">
            <v-row class="row-d-block">
              <v-subheader>Заголовок</v-subheader>
              <v-text-field outlined v-model="cert.title"></v-text-field>
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Префикс</v-subheader>
              <v-text-field outlined v-model="cert.prefix"></v-text-field>
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Номинал</v-subheader>
              <v-text-field outlined v-model="cert.nominal"></v-text-field>
            </v-row>
            <v-row class="row-d-block">
              <v-subheader>Процент</v-subheader>
              <v-text-field outlined v-model="cert.maxpercent"></v-text-field>
            </v-row>
            <v-btn :loading="loading_save" class="mt-2" @click="update">Сохранить</v-btn>
            <v-btn :loading="loading_save" class="mt-2 ml-2" @click="$store.state.downloadCsv(cert.promos.map(promo => [promo.code, promo.remaining, promo.date_from, promo.date_to]))">Выгрузить</v-btn>
            <v-row v-if="parseInt(this.$route.params.id)">
              <v-col class="pl-0">
                <v-text-field placeholder="Кол-во промо" style="display: inline-block;margin-right: 10px"
                              aria-autocomplete="none" outlined v-model="number"></v-text-field>
                <v-btn @click="generate" :loading="loading_gen">+ Сгенерировать еще</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
              v-if="parseInt(this.$route.params.id)"
              hover
              :items="cert.promos"
              :headers="promoHeader"
              item-key="id"
              :loading="loading_promos"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Партнерских промокодов нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item.client="{item, index}">
              <td>
                <ClientLink v-if="item.client" :value="item.client"></ClientLink>
              </td>
            </template>
            <template v-slot:item.n_id="{item, index}">
              <td></td>
            </template>
            <template v-slot:item.date_from="{item, index}">
              <td>
                <div style="display: flex;flex-direction: row;align-items: center;gap: 5px;max-width: 400px">
                  с
                  <Date clearable @change="saveDate(item.n_id, {date_from: item.date_from})" v-model="item.date_from"/>
                  по
                  <Date clearable @change="saveDate(item.n_id, {date_to: item.date_to})" v-model="item.date_to"/>
                  <v-btn small @click="setAllItems({date_from: item.date_from, date_to: item.date_to})">Выставить у всех</v-btn>
                </div>
              </td>
            </template>
            <template v-slot:item.index="{item, index}">
              <td>{{ index + 1 }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import ClientLink from "../../../components/ClientLink";
import Date from "../../../components/Date";

export default {
  name: "Promos",
  components: {
    Date,
    ClientLink
  },
  data() {
    return {
      promos: [],
      loading_save: false,
      loading_gen: false,
      itemsToUpdate: {},
      number: '',
      cert: {promos: []},
      promoHeader: [
        {text: '#', value: 'index'},
        {text: 'Код', value: 'code'},
        {text: 'Клиент', value: 'client'},
        {text: 'Дата создания', value: 'datetime_createObj.str'},
        {text: 'Дата активации', value: 'datetime_activateObj.str'},
        {text: 'Остаток', value: 'remaining'},
        {text: 'Срок действия', value: 'date_from'},
      ],
      loading_promos: true,
    }
  },
  methods: {
    getPromos() {
      this.$store.state.server.request('certificate/get/' + this.$route.params.id, {}, (data) => {
        this.loading_promos = false;
        this.cert = data.response;
      }, (data) => {
        this.loading_promos = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    saveDate(id, params) {
      this.itemsToUpdate[id] = params;
      // this.$store.state.server.request('certificate/itemUpdate/' + id, params, () => {
      //   this.$root.notify('Дата сохранена');
      // }, () => {
      //   this.$root.notify('Не получилось сохранить');
      // })
    },
    setAllItems(params) {
      this.cert.promos.forEach(promo => {
        for (let param in params) {
          promo[param] = params[param];
        }
          this.itemsToUpdate[promo.n_id] = params;
      })
    },
    update() {
      if (this.$refs.form.validate()) {
        this.loading_save = true;
        let params = {...this.cert};
        delete params.promos;
        params.itemsToUpdate = {...this.itemsToUpdate}
        let url = parseInt(this.$route.params.id) ? 'certificate/update/' + this.$route.params.id : 'certificate/create/'
        this.$store.state.server.request(url, params, (data) => {
          if (!parseInt(this.$route.params.id)) {
            this.$router.push('/other/partner_promos/' + data.response.n_id);
            return;
          }
          this.itemsToUpdate = {};
          this.cert = data.response
          this.$root.notify('Информация сохранена', 'success')
          this.loading_save = false
        }, (data) => {
          if (data && data.error) this.$root.notify(data.error, 'error')
          this.loading_save = false
        })
      }
    },
    generate() {
      let number = parseInt(this.number);
      this.loading_gen = true;
      this.$store.state.server.request('certificate/generate/' + this.$route.params.id, {number}, (data) => {
        this.cert = data.response
        this.$root.notify('Промо сгенерированы', 'success')
        this.loading_gen = false
        this.number = '';
      }, (data) => {
        if (data && data.error) this.$root.notify(data.error, 'error')
        this.loading_gen = false
      })
    }
  },
  mounted() {
    if (parseInt(this.$route.params.id)) this.getPromos()
  }
}
</script>

<style scoped>

</style>